import TheLayout from "../layout/TheLayout.vue";
import AdminDashboard from "../pages/admin/AdminDashboard.vue";
import AdminLogs from "../pages/admin/AdminLogs.vue";
import CreditReceipt from "../pages/admin/CreditReceipt.vue";
import Credits from "../pages/admin/Credits.vue";
import PackageIn from "../pages/admin/PackageIn.vue";
import Customers from "../pages/admin/Customers.vue";
import InjuryReport from "../pages/admin/InjuryReport.vue";
import Workers from "../pages/admin/Workers.vue";
import Checkout from "../pages/checkout/Checkout.vue";
import Checkpoints from "../pages/checkpoints/Checkpoints.vue";
import Calculator from "../pages/client/Calculator.vue";
import ClientDashboard from "../pages/client/ClientDashboard.vue";
import MyAccount from "../pages/client/MyAccount.vue";
import OrderInvoice from "../pages/invoices/OrderInvoice.vue";
import Login from "../pages/login/Login.vue";
import ForgetPassword from "../pages/misc/ForgetPassword.vue";
import ResetPassword from "../pages/misc/ResetPassword.vue";
import OfficesIndex from "../pages/offices/OfficesIndex.vue";
import OrderCreate from "../pages/orders/OrderCreate.vue";
import OrderDetail from "../pages/orders/OrderDetail.vue";
import OrderEdit from "../pages/orders/OrderEdit.vue";
import OrderLabel from "../pages/orders/OrderLabel.vue";
import OrdersIndex from "../pages/orders/OrdersIndex.vue";
import Pickups from "../pages/orders/Pickups.vue";
import ScanPackages from "../pages/packages/ScanPackages.vue";
import Register from "../pages/register/Register.vue";
import AccEndOfDayReport from "../pages/reports/AccEndOfDayReport.vue";
import AccountantEod from "../pages/reports/AccountantEod.vue";
import ClientOrderReport from "../pages/reports/ClientOrderReport.vue";
import DeliveredNotInvoiced from "../pages/reports/DeliveredNotInvoiced.vue";
import EndOfDayReport from "../pages/reports/EndOfDayReport.vue";
import OldPackageReport from "../pages/reports/OldPackageReport.vue";
import ReportsIndex from "../pages/reports/ReportsIndex.vue";
import SingleEOD from "../pages/reports/SingleEOD.vue";
import UnpaidInvoices from "../pages/reports/UnpaidInvoices.vue";
import RetailIndex from "../pages/retail/RetailIndex.vue";
import ShipmentCreate from "../pages/shipments/ShipmentCreate.vue";
import ShipmentDetail from "../pages/shipments/ShipmentDetail.vue";
import ShipmentEdit from "../pages/shipments/ShipmentEdit.vue";
import ShipmentsIndex from "../pages/shipments/ShipmentsIndex.vue";
import Taxes from "../pages/taxes/Taxes.vue";
import { store } from "../store/store.js";
import CalculatorV2 from "@/pages/client/CalculatorV2.vue";

// Layout
// Pages
// Guards
export const routes = [
	//Routes within main app layout
	{
		path: "/",
		component: TheLayout,
		beforeEnter: (to, from, next) => {
			let token = localStorage.getItem("ship_token");
			let user = localStorage.getItem("ship_user");

			if (!token || !user) {
				next({
					name: "login",
					query: {
						redirect: to.path,
					},
				});
			}

			next();
		},
		children: [
			{
				path: "/",
				name: "home",
				redirect: {
					name: "orders.index",
				},
			},

			{
				path: "/admin/dashboard",
				component: AdminDashboard,
				name: "admin.dashboard",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.id == 3003 || store.getters.authUser.id == 3692) {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},

			{
				path: "/client/dashboard",
				component: ClientDashboard,
				name: "client.dashboard",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "admin" ||
						store.getters.authUser.account_type == "worker"
					) {
						next({
							name: "admin.dashboard",
						});
					}

					next();
				},
			},
			{
				path: "/my-account",
				component: MyAccount,
				name: "profile",
			},
			// Order Routes
			{
				path: "/orders",
				component: OrdersIndex,
				name: "orders.index",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type == "accounts") {
						next({
							name: "acc.report",
						});
					}

					next();
				},
			},

			{
				path: "/orders/:id",
				component: OrderDetail,
				name: "order.detail",
			},

			{
				path: "/orders/:id/edit",
				component: OrderEdit,
				name: "order.edit",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type == "accounts") {
						next({
							name: "acc.report",
						});
					}

					if (store.getters.authUser.account_type == "basic") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},

			{
				path: "/orders/create/new",
				component: OrderCreate,
				name: "order.create",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type == "accounts") {
						next({
							name: "acc.report",
						});
					}

					if (store.getters.authUser.account_type == "basic") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},

			{
				path: "/packages/scan",
				component: ScanPackages,
				name: "packages.scan",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type == "accounts") {
						next({
							name: "acc.report",
						});
					}

					if (store.getters.authUser.account_type == "basic") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},
			{
				path: "/packages-in",
				component: PackageIn,
				name: "packages.in",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type == "accounts") {
						next({
							name: "acc.report",
						});
					}

					if (store.getters.authUser.account_type == "basic") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},
			// Invoice Routes
			{
				path: "/invoices/:id",
				component: OrderInvoice,
				name: "invoice.show",
				beforeEnter: (to, from, next) => {
					if (store.getters.authUser.id == 3003 || store.getters.authUser.id == 3692) {
						next({
							name: "orders.index",
						});
					}

					if (store.getters.authUser.account_type == "accounts") {
						next({
							name: "acc.report",
						});
					}

					next();
				},
			},
			// Shipment Routes
			{
				path: "/shipments",
				component: ShipmentsIndex,
				name: "shipments.index",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type == "accounts") {
						next({
							name: "acc.report",
						});
					}

					if (store.getters.authUser.account_type == "basic") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},
			{
				path: "/shipments/:id",
				component: ShipmentDetail,
				name: "shipments.detail",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type == "accounts") {
						next({
							name: "acc.report",
						});
					}

					if (store.getters.authUser.account_type == "basic") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},
			{
				path: "/shipments/create/new",
				component: ShipmentCreate,
				name: "shipments.create",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type == "accounts") {
						next({
							name: "acc.report",
						});
					}

					if (store.getters.authUser.account_type == "basic") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},
			{
				path: "/shipments/:id/edit",
				component: ShipmentEdit,
				name: "shipments.edit",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type == "accounts") {
						next({
							name: "acc.report",
						});
					}

					if (store.getters.authUser.account_type == "basic") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},
			// Other Routes
			{
				path: "/taxes",
				component: Taxes,
				name: "taxes",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type == "accounts") {
						next({
							name: "acc.report",
						});
					}

					next();
				},
			},
			{
				path: "/checkpoints",
				component: Checkpoints,
				name: "checkpoints",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type != "admin") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},
			{
				path: "/checkout",
				component: Checkout,
				name: "checkout",
			},

			{
				path: "/workers",
				component: Workers,
				name: "workers.index",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type != "admin") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},

			{
				path: "/offices",
				component: OfficesIndex,
				name: "offices.index",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type != "admin") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},

			{
				path: "/customers",
				component: Customers,
				name: "customers.index",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.id == 3003 || store.getters.authUser.id == 3692) {
						next({
							name: "orders.index",
						});
					}

					if (store.getters.authUser.account_type == "accounts") {
						next({
							name: "acc.report",
						});
					}

					if (store.getters.authUser.account_type == "basic") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},

			{
				path: "/reports",
				component: ReportsIndex,
				name: "reports.index",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type != "admin") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},

			{
				path: "/reports/eod",
				component: EndOfDayReport,
				name: "eod.report",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type != "admin") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},

			{
				path: "/reports/acc/eod",
				component: AccEndOfDayReport,
				name: "acc.report",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type == "worker") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},

			{
				path: "/reports/client-order-report",
				component: ClientOrderReport,
				name: "clients.report",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type == "worker") {
						next({
							name: "orders.index",
						});
					}

					if (store.getters.authUser.account_type == "basic") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},

			{
				path: "/reports/eod/:id",
				component: SingleEOD,
				name: "eod.show",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type != "admin") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},

			{
				path: "/reports/acc/:id",
				component: AccountantEod,
				name: "acc.show",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type == "worker") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},

			{
				path: "/reports/old-packages",
				component: OldPackageReport,
				name: "reports.old",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type != "admin") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},

			{
				path: "/reports/unpaid/invoices",
				component: UnpaidInvoices,
				name: "unpaid.invoices",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type != "admin") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},

			{
				path: "/reports/delivered/unpaid",
				component: DeliveredNotInvoiced,
				name: "delivered.unpaid",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type != "admin") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},

			{
				path: "/credits",
				component: Credits,
				name: "credits",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type == "accounts") {
						next({
							name: "acc.report",
						});
					}

					if (store.getters.authUser.account_type == "basic") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},

			{
				path: "/admin/logs",
				component: AdminLogs,
				name: "admin.logs",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type != "admin") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},

			{
				path: "/admin/incident",
				component: InjuryReport,
				name: "admin.incident",
				beforeEnter: (to, from, next) => {
					if (
						store.getters.authUser.account_type == "personal" ||
						store.getters.authUser.account_type == "business"
					) {
						next({
							name: "client.dashboard",
						});
					}

					if (store.getters.authUser.account_type != "admin") {
						next({
							name: "orders.index",
						});
					}

					next();
				},
			},

			{
				path: "/us-pickups",
				component: Pickups,
				name: "pickups",
			},
		],
	},
	//Routes outside of main app layout
	{
		path: "/login",
		component: Login,
		name: "login",
		beforeEnter: (to, from, next) => {
			let token = localStorage.getItem("ship_token");
			let user = localStorage.getItem("ship_user");

			let redirect = to.query.redirect || from.path || "home";

			if (token && user) {
				next({
					path: redirect,
				});
			}

			next();
		},
	},
	{
		path: "/registration",
		component: Register,
		name: "register",
		beforeEnter: (to, from, next) => {
			let token = localStorage.getItem("ship_token");
			let user = localStorage.getItem("ship_user");

			if (token && user) {
				next({
					name: from.name,
				});
			}

			next();
		},
	},

	{
		path: "/forget-password",
		component: ForgetPassword,
		name: "password.forget",
	},

	{
		path: "/reset-password",
		component: ResetPassword,
		name: "password.reset",
	},

	{
		path: "/calculator-v2",
		component: CalculatorV2,
		name: "calculator",
	},

	{
		path: "/orders-for-sale",
		component: RetailIndex,
		name: "retail.index",
	},

	{
		path: "/packages/:id/label",
		component: OrderLabel,
		name: "shipping.label",
		beforeEnter: (to, from, next) => {
			if (
				store.getters.authUser.account_type == "personal" ||
				store.getters.authUser.account_type == "business"
			) {
				next({
					name: "client.dashboard",
				});
			}

			next();
		},
	},
	{
		path: "/credits/:id",
		component: CreditReceipt,
		name: "credits.show",
		beforeEnter: (to, from, next) => {
			if (
				store.getters.authUser.account_type == "personal" ||
				store.getters.authUser.account_type == "business"
			) {
				next({
					name: "client.dashboard",
				});
			}

			next();
		},
	},
];

// export const routes = [
//     {
//         path: '*',
//         component: Maintenance,
//         name: 'maintenance',
//     }
// ]
