<template>
    <div>
        <p v-if="orders.length < 1">You do not currently have any order history.</p>
        <OrdersGrid :orders="orders" v-if="orders.length > 1"/>
    </div>
</template>

<script>
import OrdersGrid from '../../components/orders/OrdersGrid.vue';

export default {
    props: ['orders'],

    components: {
        OrdersGrid
    },

    data() {
        return {

        }
    }
}
</script>

