import { cartService, checkpointService, orderService } from "../../../services";

export default {
	fetchOrdersGridItems({ commit }, payload) {
		return new Promise((resolve, reject) => {
			orderService.fetchOrdersGridItems(payload).then(
				(response) => {
					// call the GET_ORDERS_GRID_ITEMS mutation
					commit("GET_ORDERS_GRID_ITEMS", response.data.data);
					// http success, let the calling function know that the action worked
					resolve(response.data);
				},
				(error) => {
					// http failed, let the calling function know that action did not work out
					reject(error);
				}
			);
		});
	},

	fetchOrderDetails({ commit }, payload) {
		return new Promise((resolve, reject) => {
			orderService.fetchOrderDetails(payload).then(
				(response) => {
					// call the SET_AUTH_USER mutation
					// commit('GET_ORDERS_GRID_ITEMS', response.data.data);
					// http success, let the calling function know that the action worked
					resolve(response.data);
				},
				(error) => {
					// http failed, let the calling function know that action did not work out
					reject(error);
				}
			);
		});
	},

	fetchTrackingInfo({ commit }, payload) {
		return new Promise((resolve, reject) => {
			checkpointService.getTrackings(payload).then(
				(response) => {
					// call the SET_AUTH_USER mutation
					// commit('GET_ORDERS_GRID_ITEMS', response.data.data);
					// http success, let the calling function know that the action worked
					resolve(response.data);
				},
				(error) => {
					// http failed, let the calling function know that action did not work out
					reject(error);
				}
			);
		});
	},

	fetchPackageLabel({ commit }, payload) {
		return new Promise((resolve, reject) => {
			orderService.fetchPackageLabel(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	fetchPickups({ commit }, payload) {
		return new Promise((resolve, reject) => {
			orderService.fetchPickups().then(
				(response) => {
					commit("FETCH_US_PICKUPS", response.data.data);
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	createOrder({ commit }, payload) {
		return new Promise((resolve, reject) => {
			orderService.createOrder(payload.workerID, payload.formData).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	generateManualInvoice({ commit }, payload) {
		return new Promise((resolve, reject) => {
			orderService.generateManualInvoice(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	editOrder({ commit }, payload) {
		return new Promise((resolve, reject) => {
			orderService.editOrder(payload.orderID, payload.formData).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	fetchCredits({ commit }, payload) {
		return new Promise((resolve, reject) => {
			orderService.fetchCredits().then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	fetchCreditById({ commit }, payload) {
		return new Promise((resolve, reject) => {
			orderService.fetchCreditById(payload).then(
				(response) => {
					resolve(response.data.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},

	makeCCPayment({ commit }, payload) {
		return new Promise((resolve, reject) => {
			orderService.makeCCPayment(payload).then(
				(response) => {
					resolve(response.data.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},
	deleteInvoiceUpload({ commit }, payload) {
		return new Promise((resolve, reject) => {
			orderService.deleteInvoiceUpload(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},
	deleteImageUpload({ commit }, payload) {
		return new Promise((resolve, reject) => {
			orderService.deleteImageUpload(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},
	softDeleteOrder({ commit }, payload) {
		return new Promise((resolve, reject) => {
			orderService.softDeleteOrder(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},
	fetchEditedOrdersReport({ commit }, payload) {
		return new Promise((resolve, reject) => {
			orderService.fetchEditedOrdersReport(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},
	approveForSale({ commit }, payload) {
		return new Promise((resolve, reject) => {
			orderService.approveForSale(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},
	getRetailOrders({ commit }, payload) {
		return new Promise((resolve, reject) => {
			orderService.getRetailOrders(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},
	closeOrder({ commit }, payload) {
		return new Promise((resolve, reject) => {
			orderService.closeOrder(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},
	openOrder({ commit }, payload) {
		return new Promise((resolve, reject) => {
			orderService.openOrder(payload).then(
				(response) => {
					resolve(response.data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},
	async getDeliveryCost({}, payload) {
		const res = await cartService.getDeliveryCost(payload);

		return res.data.data;
	},
	async fetchDeliveries({}, payload) {
		const res = await orderService.fetchDeliveries(payload);

		return res.data.data;
	},
	async createPackageIn({}, payload) {
		const res = await orderService.createPackageIn(payload);

		return res.data.data;
	},
	async fetchPackageIns({}) {
		const res = await orderService.fetchPackageIns();

		return res.data;
	},
	async deletePackageIns({}, payload) {
		const res = await orderService.deletePackageIns(payload);

		return res.data;
	},
	async addToSheet({}, payload) {
		const res = await orderService.addToSheet(payload);

		return res.data;
	},
	async checkHeldPackage({}, payload) {
		const res = await orderService.checkHeldPackage(payload);

		return res.data;
	},
};
